const routes=[
  {
    path:"/icon",
    meta:{
      tag:"Icon 图标"
    },
    component:()=>import("../views/lhComponents/IconView.vue")
  },
  {
    path:"/button",
    meta:{
      tag:"Button 按钮"
    },
    component:()=>import("../views/lhComponents/ButtonView.vue")
  },
  {
    path:"/checkbox",
    meta:{
      tag:"Checkbox 复选框"
    },
    component:()=>import("../views/lhComponents/CheckboxView.vue")
  },
  {
    path:"/radio",
    meta:{
      tag:"Radio 单选按钮"
    },
    component:()=>import("../views/lhComponents/RadioView.vue")
  },
  {
    path:"/pagination",
    meta:{
      tag:"Pagination 分页"
    },
    component:()=>import("../views/lhComponents/PaginationView.vue")
  },
  {
    path:"/routerLink",
    meta:{
      tag:"RouterLink 路由跳转"
    },
    component:()=>import("../views/lhComponents/RouterLinkView.vue")
  },
  {
    path:"/navMenu",
    meta:{
      tag:"NavMenu 导航菜单"
    },
    component:()=>import("../views/lhComponents/NavMenuView.vue")
  },
  {
    path:"/select",
    meta:{
      tag:"Select 选择器"
    },
    component:()=>import("../views/lhComponents/SelectView.vue")
  },
  {
    path:"/table",
    meta:{
      tag:"Table 表格"
    },
    component:()=>import("../views/lhComponents/TableView.vue")
  },
  {
    path:"/collapse",
    meta:{
      tag:"Collapse 折叠面板"
    },
    component:()=>import("../views/lhComponents/CollapseView.vue")
  },
  {
    path:"/dialog",
    meta:{
      tag:"Dialog 对话框"
    },
    component:()=>import("../views/lhComponents/DialogView.vue")
  },
  {
    path:"/empty",
    meta:{
      tag:"Empty 空状态"
    },
    component:()=>import("../views/lhComponents/EmptyView.vue")
  },
  {
    path:"/img",
    meta:{
      tag:"Img 图像"
    },
    component:()=>import("../views/lhComponents/ImgView.vue")
  },
  {
    path:"/input",
    meta:{
      tag:"Input 输入框"
    },
    component:()=>import("../views/lhComponents/InputView.vue")
  },
  {
    path:"/loading",
    meta:{
      tag:"Loading 加载中"
    },
    component:()=>import("../views/lhComponents/LoadingView.vue")
  },
  {
    path:"/message",
    meta:{
      tag:"Message 信息提示"
    },
    component:()=>import("../views/lhComponents/MessageView.vue")
  },
  {
    path:"/messageBox",
    meta:{
      tag:"MessageBox 消息弹窗"
    },
    component:()=>import("../views/lhComponents/MessageBoxView.vue")
  },
  {
    path:"/tip",
    meta:{
      tag:"Tip 提示框"
    },
    component:()=>import("../views/lhComponents/TipView.vue")
  },
  {
    path:"/codeEditor",
    meta:{
      tag:"CodeEditor 代码编辑器"
    },
    component:()=>import("../views/lhComponents/CodeEditorView.vue")
  }
]
export default routes;