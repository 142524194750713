// 引入Vue
import Vue from 'vue'
// 引入路由
import VueRouter from 'vue-router'
// 使用插件
Vue.use(VueRouter)
//import Index from '../views/Index.vue'
//import Home from '../views/home/Index.vue'
// import CodeGenerate from '../views/codeGenerate/Index.vue'
// import Bookmarks from '../views/bookmarks/Index.vue'
// import LhUI from '../views/lhUI/Index.vue'
import lhUIChildrenRoutes from '../views/lhUI/routes/index'
// import Xxl from '../views/xxl/Index.vue'
// import Sj from '../views/sj/Index.vue'
//import Jump from '../views/jump/Index.vue'

// 创建并暴露一个路由
export default new VueRouter({
  routes:[
    {
      path:'/',
      beforeEnter:(to,from,next)=>{
        let user=sessionStorage.getItem("user");
        if(user==null||user!="lh"){
          next("/login");
        }else{
          next();
        }
      },
      component: ()=>import("../views/Index.vue"),
      // redirect: '/codeGenerate',
      children:[
        {
            path:'/',
            component:()=>import("../views/home/Index.vue")
        },
        {
            path:"/bookmarks",
            component:()=>import("../views/bookmarks/Index.vue")
        },
        {
            path:"/coding",
            component:()=>import("../views/coding/Index.vue")
        }
      ]
    },
    {
      path:"/login",
      component: ()=>import("../views/login/Index.vue")
    },
    {
      path:'/codeGenerate',
      meta:{
        title:"codeGenerate代码生成"
      },
      component: ()=>import("../views/codeGenerate/Index.vue")
    },
    {
      path:'/lhUI',
      component: ()=>import("../views/lhUI/Index.vue"),
      redirect: '/install',
      children:lhUIChildrenRoutes
    }
    // {
    //     path:"/jump",
    //     component:Jump
    // }
    // {
    //     path:'/xxl',
    //     component: Xxl
    // },
    // {
    //     path:'/sj',
    //     component: Sj
    // }
  ]
})