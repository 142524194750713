<template>
<div class="app">
  <router-view/>
</div>
</template>

<script>
//import {LhButton} from 'lianghang-ui'
export default {
  name: 'App'
}
</script>

<style lang="less" scoped>
.app{
  height: 100%;
  //border: solid;
}
</style>
