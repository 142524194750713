const routes=[
  {
    path:'/install',
    meta:{
      title:"lhUI两行UI",
      tag:"安装"
    },
    component:()=>import("../views/guide/Install.vue")
  },
  {
    path:'/start',
    meta:{
      tag:"快速上手"
    },
    component:()=>import("../views/guide/Start.vue")
  },
  {
    path:'/recommenedLayout',
    meta:{
      tag:"推荐布局"
    },
    component:()=>import("../views/guide/RecommendedLayout")
  }
]
export default routes;